// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material'as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$template_onboarding_ui-primary: mat.define-palette(mat.$indigo-palette);
$template_onboarding_ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$template_onboarding_ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$template_onboarding_ui-theme: mat.define-light-theme(
                (
                        color:
                        (
                                primary: $template_onboarding_ui-primary,
                                accent: $template_onboarding_ui-accent,
                                warn: $template_onboarding_ui-warn
                        )
                )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($template_onboarding_ui-theme);

/* Custome Style Sheet */
/* For custome styles please see custom.scss in root */

/* ------------------------------------------------------------------------- */
/* You can add global styles to this file, and also import other style files */
/* ------------------------------------------------------------------------- */

// --------------
// Global Styling
// --------------
// Fonts

// Core Styling
html,
body {
  height: 100% !important;
  font-size: 12px;
}

body {
  margin: 0 !important;
  font-family: 'Roboto', sans-serif !important;
  color: var(--font-primary-colour) !important;
}

p {
  font-size: 12px;
}

a:link,
a:visited {
  font-size: 12px;
  //color: var(--input-active-colour) !important;
  color: var(--font-primary-colour) !important;
}

.splash-screen {
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  text-align: center !important;
  background-size: 100% !important;
  background-image: var(--absa-bg-color) !important;
}

.splash-screen-image {
  margin-top: 300px !important;
}

// -----------------------
// Material Design Styling
// -----------------------
.mat-form-field .mat-form-field-autofill-control {
  background-color: white !important;
}

.mat-list-base .mat-list-option.mat-list-option.older-chapter .mat-list-item-content .mat-list-text {
  color: var(--input-colour) !important;
}

.mat-form-field {
  width: 100% !important;
}

.mat-focused .mat-form-field-label {
  color: var(--input-colour) !important;
}

.mat-focused .mat-select-arrow {
  color: var(--input-active-colour) !important;
}

.mat-focused .mat-form-field-ripple {
  color: var(--input-colour) !important;
  background-color: var(--input-active-colour) !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--input-active-colour) !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--input-active-colour) !important;
}

.mat-flat-button {
  border-radius: 10px !important;
  background-color: transparent !important;
  color: var(--input-colour) !important;
  border: 1px !important;
  border-style: dashed !important;
  border-color: var(--input-active-colour) !important;
  width: 100% !important;
  padding: 15px !important;
}

button.navigation-top.mat-fab.mat-accent,
button.navigation-top.mat-mini-fab.mat-accent {
  margin-left: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--header-border-colour) !important;
  background-color: var(--header-background-colour) !important;
  color: var(--header-font-colour) !important;
  width: 100% !important;
}

.mat-icon {
  margin-top: -13px !important;
  margin-left: -4px !important;
  height: 45px !important;
  width: 45px !important;
}

.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: var(--button-active-colour) !important;
}

.mat-checkbox {
  font-size: 12px;
}

.mat-spinner {
  position: absolute !important;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.mat-spinner circle {
  stroke: var(--input-active-colour) !important;
}

// -------
// Spinner
// -------
.spinner-container {
  background-color: rgba(0, 0, 0, 0.7) !important;
  position: absolute !important;
  top: 0 !important;
  left: 0;
  height: 110%;
  width: 100%;
}

// ----------
// Onboarding
// ----------
.layout-container {
  height: 100% !important;
  padding: 20px !important;
  //background-color: #fff !important;
  //background-color: red !important;
  background-color: transparent !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.footer {
  padding: 20px !important;
  place-items: center !important;
}

ul {
  //list-style-type: '✓  ' !important;
  align-items: center !important;
}

li {
  list-style-position: outside !important;
  vertical-align: middle !important;
  letter-spacing: 0.0015em !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding-top: 6px !important;
  line-height: 21px !important;
  padding-bottom: 6px !important;
}

li::marker {
  display: block !important;
  vertical-align: middle !important;
}

// --------------
// Navigation Top
// --------------
.navigation-top {
  text-align: left !important;
  line-height: 55px !important;
  padding-left: 13px !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.navigation-header {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding-left: 13px !important;
  color: var(--font-primary-colour) !important;
}

.back-arrow {
  height: 32px !important;
  margin-top: 2px !important;
  color: var(--font-primary-colour) !important;
  background-color: transparent !important;
}


// -----------------
// Navigation Bottom
// -----------------
.navigation-bottom {
  margin: 20px 0px 20px 0px !important;
}

// ------------
// Start Screen
// ------------
.logo {
  background-image: var(--logo) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  margin: 20px auto 20px auto !important;
  height: 200px !important;
}

.welcome {
  margin: 30px 0px 5px 0px !important;
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 45px !important;
  color: #2D2222 !important;
  //text-align: center !important;
  //font-weight: bold !important;
  //font-size: 45px !important;
  //color: var(--font-title-colour) !important;
}

.welcome-sub-header {
  margin: 0px 0px 5px 0px !important;
  color: #2D2222 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}

.welcome-sub-header-entry {
  margin: 0px 0px 10px 0px !important;
  color: #2D2222 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}

.upload-container {
  padding: 8px !important;
  width: 100% !important;
  border-radius: 10px !important;
  border: 1px solid #E5E5EA !important;
  background-color: var(--button-inactive-colour) !important;
  padding-bottom: 10px !important;
}

.welcome-entry {
  margin: 30px 0px 0px 0px !important;
  color: #2D2222 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

// -------
// Stepper
// -------
.stepper-container {
  margin-top: 70px !important;
  font-size: 12px !important;
}

.stepper-title {
  margin: -25px 0px 0px -10px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: var(--stepper-title) !important;
}

.stepper-description {
  margin: -10px 0px 0px -10px !important;
  font-size: 12px !important;
  color: var(--stepper-description) !important;
}

.step-active {
  margin: -10px 1px 1px 1px !important;
  line-height: 4px !important;
  background-color: var(--step-active-colour) !important;
}

.step-inactive {
  margin: -10px 1px 1px 1px !important;
  line-height: 4px !important;
  background-color: var(--step-inactive-colour) !important;
}

.step-text {
  margin: -15px 0px 20px 20px !important;
  font-size: 12px !important;
  color: var(--step-text) !important;
}

// -------------
// Button styles
// -------------
.button-active {
  width: 100% !important;
  padding: 17px !important;
  border-radius: 10px !important;
  border: 1px solid var(--button-active-border-colour) !important;
  background-color: var(--button-active-colour) !important;
  color: var(--button-active-font-colour) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px;
}

.button-active:disabled {
  width: 100% !important;
  padding: 17px !important;
  border-radius: 10px !important;
  border: 1px solid #939598 !important;
  background-color: #939598 !important;
  color: #6f6f6f !important;
  opacity: 0.6 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px;
}

.button-inactive {
  width: 100% !important;
  padding: 17px !important;
  border-radius: 10px !important;
  border: 1px solid var(--button-inactive-border-colour) !important;
  background-color: var(--button-inactive-colour) !important;
  color: var(--button-inactive-font-colour) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px;
}

// ------
// Upload
// ------
.upload-info {
  //padding: 7px !important;
  //background-color: #f1f1f1 !important;
  //border-radius: 10px !important;
  //font-size: 12px !important;
}

.upload-info-file {
  padding: 7px !important;
  background-color: #f1f1f1 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
}

.upload-info-file::before {
  padding-right: 7px !important;
  font-size: 12px !important;
  color: #00d100 !important;
  content: "✓" !important;
}

.upload-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: var(--font-primary-colour) !important;
}

.upload-button {
  //padding: 10px 0px 10px 10px !important;
  position: relative !important;
  padding-bottom: 10px !important;
}

.upload-details {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: var(--font-primary-colour) !important;
}


.card-outline {
  border: 1px solid #E5E5EA;
  border-radius: 8px !important;
}

.button-file-upload {
  padding: 8px !important;
  width: 100% !important;
  text-align: start !important;
  border-radius: 10px !important;
  border: 1px solid #E5E5EA !important;
  background-color: var(--button-inactive-colour) !important;
  color: var(--font-primary-colour) !important;
  font-weight: 500 !important;
}

.button-file-remove {
  padding: 8px !important;
  width: 100% !important;
  //text-align: end !important;
  border-radius: 10px !important;
  border: 1px solid #E5E5EA !important;
  background-color: var(--button-inactive-colour) !important;
  color: var(--font-primary-colour) !important;
  font-weight: 500 !important;
}

// ------------
// Notification
// ------------
.notification-title {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 45px !important;
  text-align: center !important;
  margin: 20px 0px 30px 0px !important;
  color: var(--font-primary-colour) !important;
}

.notification-details {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: center !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.general-header {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.mat-grid-tile-content{
  justify-content: flex-start !important ;
}

.notification-details-string {
  text-align: center !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-weight: bold !important;
}

.notification-icon-green {
  //margin-left: 40px !important;
  //padding: 0px 0px 50px 0px !important;
  //font-size: 280px !important;
  //color: #00d100 !important;
  //align-content: center !important;
}

.notification-icon-red {
  margin-left: 40px !important;
  padding: 0px 0px 50px 0px !important;
  font-size: 280px !important;
  color: #dc3545 !important;
}

.notification-background {
  //align-content: center !important;
  //margin-top: 40px !important;
  //margin-bottom: 40px !important;
  //margin-left: auto !important;
  //margin-right: auto !important;
  //align-content: center !important;
  //width: 250px !important;
  //height: 250px !important;
  //line-height: 200px !important;
  //border-radius: 50% !important;
  //text-align: center !important;
  //background: #f1f1f1 !important;
  text-align: center !important;
  margin-top: 20% !important;
}

.terms-tick {
  padding-right: 7px !important;
  font-size: 12px !important;
  color: #00d100 !important;
  content: "✓" !important;
}

.terms-agreement-header {
  font-size: 12px !important;
  padding-top: 15px !important;
  font-weight: bold !important;
}

.business-details-header {
  font-size: 21px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  padding-top: 15px !important;
}

.settings-icon {
  text-align: center !important;
  margin-top: 70px !important;
}

.mat-form-field.mat-form-field-should-float label {
  font-size: 12px;
  color: var(--font-primary-colour) !important
}

.mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 0) !important;
  background-size: 0px 0% !important;
  background-repeat: repeat-x !important;
  color: rgba(0, 0, 0, 1) !important;
}

.mat-radio-label-content {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  font-style: normal !important;
  font-family: 'Roboto' !important;
  letter-spacing: 0.004em !important;
}

.start-bg {
  background-image: var(--absa-bg-color) !important;
}

.apply-card {
  margin-top: 80px !important;
}

.apply-card-image {
  text-align: center !important;
  padding-bottom: 65px !important;
}

.apply-card-border {
  border-radius: 10px !important;
}

.apply-detail {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.apply-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  padding-bottom: 10px !important;
}

.padded-link {
  padding-left: 25px !important;
  padding-bottom: 16px !important;
}

.mat-form-field-label {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  font-style: normal !important;
  font-family: 'Roboto' !important;
  letter-spacing: 0.004em !important;
  color: var(--font-primary-colour) !important;
}

.mat-input {
  font-size: 16px !important;
}

.mat-form-field {
  font-size: 16px !important;
}

.mat-error {
}

.field-error-message {
  margin-top: -15px;
  padding-bottom: 8px !important;
}

// CUSTOM STYLE

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 1) !important;
}

.mat-input-element:disabled {
  color: rgba(0, 0, 0, 1) !important;
}

.input:disabled {
  color: rgba(0, 0, 0, 1) !important;
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 1) !important;
}

.upload-size-error {
  padding: 7px !important;
  font-size: 12px !important;
  color: red !important;
}