:root {
  // --------------------
  // ABSA Default Styling
  // --------------------
  // Logo
  --logo: url('/assets/images/absa.png');
  --splash: url('/assets/splash/absa-splash.png');
  --start-bg: url('/assets/images/absa-bg.png');
  // Font
  // TK --font-primary-colour: #939598;
  --font-primary-colour: rgba(0, 0, 0, 1);
  //--font-primary-colour: black;
  --font-title-colour: #f0315a;
  // Input Fields
  --input-active-colour: #f0315a;
  --input-inactive-colour: #939598;
  // Navigation Top
  --header-border-colour: #939598;
  --header-background-colour: #f1f1f1;
  --header-font-colour: #939598;
  // Button - Active
  --button-active-colour: #f0315a;
  --button-active-border-colour: #f0315a;
  --button-active-font-colour: #fff;
  // Button - Inactive
  --button-inactive-colour: transparent;
  --button-inactive-border-colour: #f0315a;
  --button-inactive-font-colour: #f0315a;
  // Stepper
  --stepper-title: #f0315a;
  //--stepper-description: #939598;
  //--step-text: #939598;
  --stepper-description: rgba(0, 0, 0, 0.75);
  --step-text: rgba(0, 0, 0, 0.75);
  --step-active-colour: #f0315a;
  --step-inactive-colour: #939598;

  //--absa-bg-color: linear-gradient(#de5c17, red)
  --absa-bg-color: linear-gradient(
                  180deg,
                  hsl(26deg 100% 53%) 0%,
                  hsl(23deg 97% 54%) 21%,
                  hsl(20deg 94% 54%) 30%,
                  hsl(16deg 91% 54%) 39%,
                  hsl(13deg 87% 54%) 46%,
                  hsl(9deg 83% 54%) 54%,
                  hsl(5deg 79% 53%) 61%,
                  hsl(360deg 75% 53%) 69%,
                  hsl(354deg 78% 48%) 79%,
                  hsl(346deg 99% 42%) 100%
  );
}
